import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import i18n from './plugins/i18n';

Vue.config.productionTip = false
Vue.prototype.$http = axios;

if (process.env.NODE_ENV == 'production') {
  Vue.prototype.baseUrl = '/api';
}
else {
  Vue.prototype.baseUrl = 'http://localhost:5000';
}

axios.interceptors.request.use(async config => {
  var access_token = localStorage.getItem('access_token');
  if (access_token) {
    config.headers.common['Authorization'] = `Bearer ${access_token}`;
  }
  return config;
}, async error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(async response => {
  return response;
}, async error => {
  console.log(error.config, error.response);
  const errorAPI = error.config;
  if (error.response.status === 401 &&
    errorAPI.retry === undefined &&
    router.currentRoute.path != '/') {
    errorAPI.retry = true;
    //router.push('/');
    //await refreshToken();
    return await axios(errorAPI);
  }
  return Promise.reject(error);
});

Vue.filter('mediumDate', value => {
  if (value) {
    if (typeof (value) === 'object') {
      return moment(value).format('YYYY-MM-DD');
    }
    return moment(String(value)).format('YYYY-MM-DD');
  }
});

Vue.filter('monthDay', value => {
  if (value) {
    if (typeof (value) === 'object') {
      return moment(value).format('MM/DD');
    }
    return moment(String(value)).format('MM/DD');
  }
});

Vue.filter('mediumTime', value => {
  if (value) {
    if (typeof (value) === 'object') {
      return moment(value).format('HH:mm:ss');
    }
    return moment(String(value)).format('HH:mm:ss');
  }
});

Vue.filter('shortDateTime', value => {
  if (value) {
    if (typeof (value) === 'object') {
      return moment(value).format('HH:mm');
    }
    return moment(String(value)).format('MM.DD HH:mm');
  }
});

Vue.filter('shortTime', value => {
  if (value) {
    if (typeof (value) === 'object') {
      return moment(value).format('HH:mm');
    }
    return moment(String(value)).format('HH:mm');
  }
});

Vue.filter('minuteDate', value => {
  if (value) {
    if (typeof (value) === 'object') {
      return moment(value).format('YYYY-MM-DD HH:mm');
    }
    return moment(String(value)).format('YYYY-MM-DD HH:mm');
  }
});

Vue.filter('isoDate', value => {
  if (value) {
    if (typeof (value) === 'object') {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    }
    return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
  }
});

Vue.filter('weekDay', (value, locale) => {
  var week = ['일', '월', '화', '수', '목', '금', '토'];
  if (locale == 'en') {
    week = ['Sun ', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  }
  if (value) {
    if (typeof (value) === 'object') {
      return week[moment(value).format('e')];
    }
    return week[moment(String(value)).format('e')];
  }
});

Vue.filter('number', (number, digit) => {
  var str = '';
  for (var i = 0; i < digit; i++) {
    str += '0';
  }
  return numeral(!isNaN(parseFloat(number)) && isFinite(number) ? number :
    number && isFinite(number) ? number.replace(/|/gi,'') : ''
  ).format('0,0' + (digit == 0 ? '' : '.' + str));
});

Vue.filter('currency', value => {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
});

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
