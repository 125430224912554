import Vue from 'vue';
import Router from 'vue-router';

const routerOptions = [
  { path: '/', component: 'LoginForm', meta: { requiresAuth: false, title: '로그인.제목' } },
  { path: '/dashboard', component: 'DashBoard', meta: { title: '홈.제목' } },
  { path: '/device', component: 'DeviceEnd', meta: { title: '수거함관리.메뉴' } },
  { path: '/device/start', component: 'DeviceStart', meta: { title: '수거함관리.메뉴' } },
  { path: '/monitor', component: 'MonitorList', meta: { title: '모니터링.메뉴' } },
  { path: '/work', component: 'WorkList', meta: { title: '근무일정.메뉴' } },
  { path: '/rule', component: 'WasteTypeList', meta: { title: '폐기물취급규정.메뉴' } },
  { path: '/mypage', component: 'MyPage', meta: { title: '내정보.메뉴' } },
  { path: '/notice', component: 'NoticeList', meta: { title: '공지사항.메뉴' } },
  { path: '*', component: 'NotFound', meta: { title: '페이지 없음' }},
];

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`../components/${route.component}.vue`),
  };
});

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

const defaultTitle = '';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title + defaultTitle;
  });
});

export default router;