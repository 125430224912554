<template>
  <v-app>
    <v-card elevation="0" class="rounded-0" :class="{'full-height':$router.currentRoute.path=='/'}">
      <v-toolbar v-show="$router.currentRoute.path!='/'" elevation="0" outlined>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-show="$router.currentRoute.path!='/dashboard'"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-center pl-0" style="width:100%">{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>

        <div style="display:scroll;position:fixed;left:80%;top:10px;">
          <v-row no-gutters>
            <v-col>
              <v-img src="@/assets/ko.png" max-width="30" @click="changeLocale('ko')"></v-img>
            </v-col>
            <v-col>
              <v-img src="@/assets/en.png" max-width="30" @click="changeLocale('en')"></v-img>
            </v-col>
          </v-row>
        </div>

        <v-btn icon v-show="$router.currentRoute.path!='/' && $router.currentRoute.path!='/dashboard'">
          <v-icon @click="$router.back()">mdi-less-than</v-icon>
        </v-btn>
      </v-toolbar>
      <v-navigation-drawer v-model="drawer"
        absolute left temporary>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">{{user.name}}</v-list-item-title>
            <v-list-item-subtitle><a @click="logout()">{{ $t('앱.로그아웃') }}</a></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item-group v-model="group">
            <v-list-item
              v-for="item in menuItems"
              :key="item.title"
              :to="item.path" link>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-main :class="{'full-height':$router.currentRoute.path=='/'}">
        <router-view @login="login"></router-view>
      </v-main>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    title: '',
    user: {},
    drawer: false,
    group: null,
    menuItems: [
      { title: '홈', path: '/dashboard'},
      { title: '수거함 관리', path: '/device'},
      { title: '모니터링', path: '/monitor'},
      { title: '근무 일정', path: '/work'},
      { title: '내 정보 보기', path: '/mypage'},
      { title: '공지사항', path: '/notice'},
    ],
  }),
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  },
  watch: {
    $route(to) {
      this.title = this.$t(to.meta.title);
    },
    group() {
      this.drawer = false;
    },
    locale() {
      this.initMenu();
    }
  },
  mounted() {
    this.login();
    this.initMenu();
  },
  methods: {
    initMenu() {
      this.title = this.$t(this.$route.meta.title);
      this.menuItems[0].title = this.$t('홈.메뉴');
      this.menuItems[1].title = this.$t('수거함관리.메뉴');
      this.menuItems[2].title = this.$t('모니터링.메뉴');
      this.menuItems[3].title = this.$t('근무일정.메뉴');
      this.menuItems[4].title = this.$t('내정보.메뉴');
      this.menuItems[5].title = this.$t('공지사항.메뉴');
    },
    changeLocale(locale) {
      localStorage.setItem('locale', locale);
      this.$i18n.locale = locale;
      this.initMenu();
    },
    async login() {
      this.user = JSON.parse(localStorage.getItem('user'));
      if (!this.user) {
        this.user = {};
      }
    },
    async logout() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      this.$router.push('/');
    },
  },
};
</script>

<style>
@font-face {
  font-family: 'SCDream4';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.v-application { font-family: SCDream4 !important; }
table th:first-child { border-top:1px solid #dddddd; }
table th + th { border-left:1px solid #dddddd; border-top:1px solid #dddddd; }
table th + td { border-left:1px solid #dddddd; border-bottom:1px solid #dddddd; }
table tr:first-child th + td { border-top:1px solid #dddddd; }
table tr th + td + th { border-top:1px solid #dddddd; }
th { background-color: #eeeeee; }
table td:first-child { border-bottom:1px solid #dddddd; }
table td + td { border-left:1px solid #dddddd; border-bottom:1px solid #dddddd; }
a { text-decoration: none; }
.full-height { min-height: 100%; }
</style>